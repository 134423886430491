import { SelectOption } from '@buildbox/components'
import { createElement, FormEvent, useEffect, useState } from 'react'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import { trendCategory, TrendCategoryEnum } from 'shared/interfaces/trend'
import {
	IModalOptionsProps,
	IModalTrend,
	IModalTrendProps,
	IModalValueProps,
	IViewProps
} from './types'
import {
	ArrayIsEmpty,
	handleInitializeArrayOptions,
	IMAGE_INITIAL_VALUE,
	INITIAL_BODY_VALUE
} from 'shared/util/Consts'

import ModalTrend from './view'
import { IAttachment } from 'shared/interfaces/attachment'
import { IUser } from 'shared/interfaces/user'
import { IImageUpload } from 'shared/components/ImageUpload/types'
import { fetchAuthors, fullName } from 'shared/services/user.service'
import { deleteTrend } from 'shared/services/trend.service'
import cogoToast from 'cogo-toast'
import { errorMessages, successMessages } from 'shared/util/Messages'
import cogoDefaultOptions from 'shared/util/toaster'
import { serialize } from 'shared/components/EditorTextBox/util'
import { Node } from 'slate'
import { updateTrend, createTrend } from '../../shared/services/trend.service'
import {
	isYoutubeVideo,
	normalizeYoutubeLink,
	isPowerBIGraph
} from 'shared/util/youtubeValidator'
import { mapCategoryContent } from 'shared/util/translate'
import { upload, remove } from 'shared/services/media.service'

function ModalTrendContainer(props: IModalTrendProps): JSX.Element {
	const {
		isActive,
		modalAction,
		currentTrend,
		hideModal,
		reloadTrendsPage,
		reloadDeleteTrend
	} = props

	const { user } = useTypedSelector(['user'])
	const [title, setTitle] = useState<string>('')
	const [subtitle, setSubtitle] = useState<string>('')
	const [youtubeLink, setYoutubeLink] = useState<string>('')
	const [author, setAuthor] = useState<string>('')
	const [authorsOptions, setAuthorsOptions] = useState<SelectOption[]>([])
	const [selectedAuthor, setSelectedAuthor] = useState<SelectOption | null>(
		null
	)
	const [category, setCategory] = useState<TrendCategoryEnum>('POLITICS')
	const [categoryOptions, setCategoryOptions] = useState<SelectOption[]>([])
	const [selectedCategory, setSelectedCategory] = useState<SelectOption | null>(
		null
	)
	const [body, setBody] = useState<Node[]>(INITIAL_BODY_VALUE)
	const [pdfs, setPdfs] = useState<IAttachment[]>([])
	const [emailNotificate, setEmailNotificate] = useState<boolean>(false)
	const [notificate, setNotificate] = useState<boolean>(true)
	const [authors, setAuthors] = useState<IUser[]>([])
	const [viewErrors, setViewErrors] = useState<string[]>([])
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [isFormValid, setFormValid] = useState<boolean | undefined>(false)
	const [image, setImage] = useState<IImageUpload>(IMAGE_INITIAL_VALUE)
	const [isPublic, setIsPublic] = useState<boolean>(false)
	const [switchValue, setSwitchValue] = useState({
		portuguese: false,
		english: false
	})
	const [ArrayOptions, setArrayOptions] = useState<string[]>([])

	const [graphLink, setGraphLink] = useState('')

	function handleGraphLink(event: React.ChangeEvent<HTMLInputElement>) {
		setGraphLink(event.target.value)
	}

	function handleArrayOptions() {
		if (switchValue.english && switchValue.portuguese) {
			setArrayOptions(['PT', 'EN'])
		} else if (switchValue.english) {
			setArrayOptions(['EN'])
		} else if (switchValue.portuguese) {
			setArrayOptions(['PT'])
		} else {
			setArrayOptions([])
		}
	}

	function initializeTrendFields() {
		setTitle(currentTrend.title)
		setSubtitle(currentTrend.subtitle)
		setCategory(currentTrend.category)
		currentTrend.author === ''
			? setAuthor(user._id)
			: setAuthor(currentTrend.author)
		setBody(JSON.parse(currentTrend.body))
		setImage({ file: null, url: currentTrend.coverImage })
		setNotificate(currentTrend.notificate)
		setYoutubeLink(currentTrend.videoLink)

		setSwitchValue(handleInitializeArrayOptions(currentTrend.languages))
		setPdfs(currentTrend.attachments)
		setEmailNotificate(currentTrend.emailNotificate)
		setIsPublic(currentTrend.isPublic)
		setGraphLink(currentTrend.webPageURL || '')
	}

	async function getAllAuthors() {
		try {
			const authors = await fetchAuthors()
			setAuthors(authors)
		} catch (err) {}
	}

	function findAuthorSelect(): SelectOption | null {
		const authorTrendCreator = authors.find((x) => {
			return x._id === String(author)
		})

		if (authorTrendCreator === undefined) return null

		return {
			label: fullName(authorTrendCreator),
			value: authorTrendCreator._id
		}
	}

	async function handleDelete(TrendId: string) {
		try {
			setIsLoading(!isLoading)
			await deleteTrend(TrendId)
			cogoToast.success(successMessages.trendDeleted, cogoDefaultOptions)
			hideModal()
			reloadDeleteTrend()
		} catch (err) {
		} finally {
			setIsLoading(!isLoading)
		}
	}

	async function handleCoverImage(image: IImageUpload) {
		if (image.file) {
			return await upload(image.file)
		}

		return image.url || ''
	}

	async function handleUploadAttachment(pdf: IAttachment) {
		if (!pdf.file) return
		const url = await upload(pdf.file)
		return {
			url,
			name: pdf.name,
			size: pdf.size
		}
	}

	async function handleSubmit(e: FormEvent<HTMLButtonElement>) {
		e.preventDefault()

		const errorsCount = handleViewError()

		if (!!!errorsCount) {
			setIsLoading(!isLoading)

			let coverImage = await handleCoverImage(image)

			const trend: IModalTrend = {
				title: title,
				subtitle: subtitle,
				category: category,
				author: author,
				createdBy: user._id,
				coverImage: coverImage,
				body: JSON.stringify(body),
				notificate: notificate,
				videoLink: normalizeYoutubeLink(youtubeLink),
				languages: ArrayOptions,
				attachments: [] as IAttachment[],
				emailNotificate: emailNotificate,
				isPublic: isPublic,
				webPageURL: graphLink
			}

			try {
				if (currentTrend._id) {
					const toUpload = pdfs.filter((pdf) => !pdf._id && !pdf.url)
					const toDelete = currentTrend.attachments.filter(
						(attachment) =>
							attachment._id && attachment.url && !pdfs.includes(attachment)
					)
					const toKeep = pdfs.filter(
						(pdf) => !toUpload.includes(pdf) && !toDelete.includes(pdf)
					)

					toDelete.map((pdf) => remove(String(pdf.url)))

					Promise.all(toUpload.map(handleUploadAttachment)).then(
						async (response) => {
							const attachments = [...response, ...toKeep]
							await updateTrend(currentTrend._id, {
								...trend,
								attachments: attachments as IAttachment[]
							})
							cogoToast.success(
								successMessages.trendUpdated,
								cogoDefaultOptions
							)
							hideModal()
							reloadTrendsPage()
						}
					)
				} else {
					Promise.all(pdfs.map(handleUploadAttachment)).then(
						async (response) => {
							await createTrend({
								...trend,
								attachments: response as IAttachment[]
							})
							cogoToast.success(
								successMessages.trendCreated,
								cogoDefaultOptions
							)
							hideModal()
							reloadTrendsPage()
						}
					)
				}
			} catch (err) {
			} finally {
				setIsLoading(!isLoading)
				//				setImage(undefined)
			}
		}
	}
	function handleViewError() {
		const errors: string[] = []

		const verifyFiels = verifyFields()
		const verifyLink = youtubeLink !== '' ? isYoutubeVideo(youtubeLink) : true

		const graphLinkError = graphLink !== '' ? isPowerBIGraph(graphLink) : true

		if (!verifyLink) {
			errors.push(errorMessages.insertAValidLink)
		}

		if (!verifyFiels) {
			errors.push('Preencha todos os campos.')
		}

		if (!graphLinkError) {
			errors.push('O link do grafico precisa ser uma URL valida')
		}

		if (ArrayIsEmpty(ArrayOptions)) {
			errors.push('Preencha ao menos um idioma.')
		}

		setViewErrors(errors)
		return errors.length
	}

	function verifyFields() {
		const allFieldsFilled = areAllFieldsFilled([title.trim()])

		const bodyIsValid = !!serialize(body).length
		const bodyString = JSON.stringify(body)

		const anyFieldChange = hasAnyFieldChange(
			title.trim(),
			subtitle.trim(),
			author,
			category,
			image.url,
			bodyString,
			notificate,
			pdfs,
			ArrayOptions,
			youtubeLink,
			emailNotificate,
			isPublic,
			graphLink
		)

		const formValidate = allFieldsFilled && anyFieldChange && bodyIsValid

		setFormValid(formValidate)

		return formValidate
	}

	function areAllFieldsFilled(fields: string[]) {
		return fields.every((item) => {
			return item.length > 0
		})
	}

	function hasAnyFieldChange(
		pTitle: string,
		pSubtitle: string,
		pAuthor: string,
		pCategory: TrendCategoryEnum,
		pPreviewImage: string,
		pBodyString: string,
		pNotificate: boolean,
		pdfs: IAttachment[],
		language: string[],
		youtubeLink: string,
		pEmailNotificate: boolean,
		pIsPublic: boolean,
		graphLink: string
	) {
		if (pTitle !== currentTrend.title) return true
		if (pSubtitle !== currentTrend.subtitle) return true
		if (pCategory !== currentTrend.category) return true
		if (pAuthor !== currentTrend.author) return true
		if (pPreviewImage !== currentTrend.coverImage) return true
		if (pBodyString !== currentTrend.body) return true
		if (pNotificate !== currentTrend.notificate) return true
		if (pEmailNotificate !== currentTrend.emailNotificate) return true
		if (JSON.stringify(pdfs) !== JSON.stringify(currentTrend.attachments))
			return true
		if (language !== currentTrend.languages) return true
		if (youtubeLink !== currentTrend.videoLink || youtubeLink === null)
			return true
		if (pIsPublic !== currentTrend.isPublic) return true

		if (graphLink !== currentTrend.webPageURL) return true
	}

	function handleSelectChange(selectedOption: any) {
		// SelectOption | null
		if (!selectedOption) return

		setAuthor(selectedOption.value)
		setSelectedAuthor(selectedOption)
	}

	function handleEffectAuthors() {
		const authorsOptions = authors.map((item) => ({
			value: item._id,
			label: fullName(item)
		}))

		setAuthorsOptions(authorsOptions)
	}

	function handleSelectCategoryChange(selectedOption: any) {
		if (!selectedOption) return
		setSelectedCategory(selectedOption)
		setCategory(selectedOption?.value as TrendCategoryEnum)
	}

	function handleEffectCategories() {
		const categoryOptions = trendCategory.map((item) => ({
			value: item,
			label: mapCategoryContent(item)
		}))

		setCategoryOptions(categoryOptions)
	}

	useEffect(() => {
		setSelectedCategory({
			label: mapCategoryContent(category),
			value: category
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [category, trendCategory])

	function handleEditor(value: Node[]) {
		setBody(value)
	}

	function handleImage(image: IImageUpload) {
		setImage(image)
	}

	function handleTitle(title: string) {
		setTitle(title)
	}

	function handleYoutubeLink(e: React.ChangeEvent<HTMLInputElement>) {
		setYoutubeLink(e.target.value)
	}

	function handleSubtitle(subtitle: string) {
		setSubtitle(subtitle)
	}

	function handleSetPdf(value: IAttachment[]) {
		setPdfs(value)
	}

	const modalValue: IModalValueProps = {
		title: title,
		subtitle: subtitle,
		image: image,
		youtubeLink: youtubeLink,
		selectedAuthor: selectedAuthor,
		body: body,
		notificate: notificate,
		emailNotificate: emailNotificate,
		isFormValid: isFormValid,
		languages: ArrayOptions,
		selectedCategory: selectedCategory,
		isPublic: isPublic
	}

	const modalOptions: IModalOptionsProps = {
		categoryOptions: categoryOptions,
		authorsOptions: authorsOptions,
		viewErrors: viewErrors
	}

	useEffect(() => {
		setSelectedAuthor(findAuthorSelect())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [author, authors])

	useEffect(handleEffectAuthors, [authors])
	useEffect(handleEffectCategories, [trendCategory])

	useEffect(() => {
		initializeTrendFields()
		getAllAuthors()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentTrend])

	useEffect(() => {
		verifyFields()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		title,
		subtitle,
		author,
		category,
		body,
		image,
		notificate,
		youtubeLink,
		pdfs,
		ArrayOptions,
		emailNotificate,
		isPublic,
		graphLink
	])

	useEffect(() => {
		initializeTrendFields()
		getAllAuthors()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(handleArrayOptions, [switchValue])

	const viewProps: IViewProps = {
		isActive,
		modalAction,
		currentTrend,
		hideModal,
		isLoading,
		initializeTrendFields,
		handleImage,
		modalValue,
		handleSubmit,
		modalOptions,
		handleDelete,
		handleEditor,
		handleSelectChange,
		handleSelectCategoryChange,
		handleTitle,
		handleYoutubeLink,
		handleSubtitle,
		handleSetPdf,
		setNotificate,
		setEmailNotificate,
		setIsPublic,
		setSwitchValue,
		switchValue,
		graphLink,
		handleGraphLink
	}

	return createElement(ModalTrend, viewProps)
}

export default ModalTrendContainer
