import React, { createElement, useEffect, useState } from 'react'

import { TableNavProps } from '@buildbox/components'

import { ITableRow, ISelectedEvent, IViewProps } from './types'
import { IEvent, IEventsPage } from '../../shared/interfaces/event'
import EventsList from './view'
import { fetchEventsPage, initializeEvent } from 'shared/services/event.service'

import { format } from 'date-fns'
import { IModalDeleteTarget } from 'shared/components/ModalDelete/types'
import { useDebounce } from 'use-debounce/lib'
import { backScrollBody } from 'shared/util/scroll'

const EVENTS_PER_PAGE = 10

function EventListContainer(): JSX.Element {
	const [tableRows, setTableRows] = useState<ITableRow[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const [selectedEvent, setSelectedEvent] = useState<ISelectedEvent>({
		status: false,
		event: initializeEvent()
	})

	const [eventToDelete, setEventToDelete] = useState<IModalDeleteTarget | null>(
		null
	)

	const [eventsPage, setEventsPage] = useState<IEventsPage>({
		pageIndex: 1,
		numberOfPages: 0,
		totalDocs: 0,
		pageContent: []
	})

	const [search, setSearch] = useState('')
	const [searchDebounce] = useDebounce(search, 1000)

	async function getEventsPage(pageIndex: number, searchString = '') {
		try {
			setIsLoading(true)
			const pageData = await fetchEventsPage(
				pageIndex,
				EVENTS_PER_PAGE,
				searchString
			)

			setEventsPage(pageData)

			handleRefetch(pageIndex, pageData.pageContent)

			const rows = pageData.pageContent.map((event) => {
				return {
					date: formatDate(event.date),
					title: renderTitle(event.title),
					subtitle: renderSubtitle(event.subtitle),
					tier: event.tier,
					// attending: event.attending?.length || 0,
					// feedback: event.feedback?.length || 0,
					attendeesPercentage:
						`${event.attending?.length} ${toPercentage(
							event.attendeesPercentage
						)}` || '',
					feedbackPercentage:
						`${event.feedback?.length}  ${toPercentage(
							event.feedbackPercentage
						)}` || '',
					elegibleCustomers: `${event.elegibleCustomers}`,
					fileTextIcon: renderFileTextIcon(event),
					trashIcon: renderTrashIcon(event)
				}
			})

			setTableRows(rows)
		} catch (err) {
			handleRefetch(eventsPage.pageIndex, eventsPage.pageContent)
		} finally {
			setIsLoading(false)
		}
	}
	function renderTitle(title: string): JSX.Element {
		return <p className='event-title'>{title}</p>
	}

	function renderSubtitle(subtitle: string): JSX.Element {
		return <p className='event-subtitle'>{subtitle}</p>
	}
	function toPercentage(n: number): string {
		return `(${(n * 100).toFixed(1)}%)`
	}

	function handleRefetch(pageIndex: number, arr: any[]) {
		const isEmpty = !!!arr.length
		const notFirstPage = pageIndex !== 1

		if (isEmpty && notFirstPage) {
			getEventsPage(1, search)
		}
	}

	function renderFileTextIcon(event: IEvent): JSX.Element {
		return (
			<button
				className='icon'
				onClick={() => setSelectedEvent({ status: true, event: event })}
			>
				<img
					className='img'
					src={require('../../assets/images/file-text.svg')}
					alt='Detalhes do Evento'
				/>
			</button>
		)
	}

	function renderTrashIcon(event: IEvent): JSX.Element {
		return (
			<button
				className='icon'
				onClick={() => setEventToDelete({ id: event._id, name: event.title })}
			>
				<img
					className='img'
					src={require('../../assets/images/trash.svg')}
					alt='Deletar do Evento'
				/>
			</button>
		)
	}

	function formatDate(eventDate: string) {
		const toDate = new Date(eventDate)
		const day = format(toDate, 'dd')
		const monthName = format(toDate, 'MMM').toUpperCase()

		return (
			<div className='event-date'>
				<strong>{day}</strong>
				<span>{monthName}</span>
			</div>
		)
	}

	const tableColumns: Object[] = [
		{ Header: 'Data', accessor: 'date', sortType: 'basic' },
		{ Header: 'Título', accessor: 'title', sortType: 'basic' },
		{ Header: 'Subtítulo', accessor: 'subtitle', sortType: 'basic' },
		{ Header: 'Tier', accessor: 'tier', sortType: 'basic' },
		// { Header: 'Pré-Inscrição', accessor: 'attending', sortType: 'basic' },
		// { Header: 'Feedback', accessor: 'feedback', sortType: 'basic' },
		{
			Header: 'Pessoas Elegíveis',
			accessor: 'elegibleCustomers',
			sortType: 'basic'
		},
		{
			Header: 'Pré Inscrição Nº(%)',
			accessor: 'attendeesPercentage',
			sortType: 'basic'
		},
		{
			Header: 'Feedback Nº(%)',
			accessor: 'feedbackPercentage',
			sortType: 'basic'
		},
		{
			Header: '',
			accessor: 'fileTextIcon',
			disableSortBy: true
		},
		{
			Header: '',
			accessor: 'trashIcon',
			disableSortBy: true
		}
	]

	function handleSelectEvent(event: IEvent, status: boolean) {
		setSelectedEvent({ status: status, event: event })
	}

	function handleEndModal() {
		handleSelectEvent(initializeEvent(), false)
		getEventsPage(eventsPage.pageIndex, search)
		backScrollBody()
	}

	function handleEndDeleteModal() {
		setEventToDelete(null)
		getEventsPage(eventsPage.pageIndex, search)
		backScrollBody()
	}

	function handleCloseModal() {
		handleSelectEvent(initializeEvent(), false)
		backScrollBody()
	}

	function handleCloseDeleteModal() {
		setEventToDelete(null)
		backScrollBody()
	}

	function handleSearch(value: string): void {
		setSearch(value)
	}

	function handleSearchDebounce() {
		getEventsPage(1, searchDebounce)
	}

	useEffect(handleSearchDebounce, [searchDebounce])

	// The index of table is normal starting in 0 but the paginated WS is 1
	const navProps: TableNavProps = {
		nextPage: (pageIndex) => getEventsPage(pageIndex + 1, search),
		previousPage: (pageIndex) => getEventsPage(pageIndex + 1, search),
		gotoPage: (pageIndex) => getEventsPage(pageIndex + 1, search),
		pageCount: eventsPage.numberOfPages,
		pageIndex: eventsPage.pageIndex - 1,
		totalDocs: eventsPage.totalDocs
	}

	const viewProps: IViewProps = {
		isLoading,
		tableColumns,
		tableRows,
		eventsPerPage: EVENTS_PER_PAGE,
		navProps,
		handleSelectEvent,
		selectedEvent,
		handleCloseModal,
		handleCloseDeleteModal,
		eventToDelete,
		handleSearch,
		search,
		handleEndModal,
		handleEndDeleteModal
	}
	return createElement(EventsList, viewProps)
}

export default EventListContainer
