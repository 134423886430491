import { SelectOption } from '@buildbox/components'
import {
	ChangeEvent,
	createElement,
	FormEvent,
	useCallback,
	useEffect,
	useState
} from 'react'
import { useTypedSelector } from 'shared/hooks/useTypedSelector'
import {
	IModalOptionsProps,
	IModalReport,
	IModalReportProps,
	IModalValueProps,
	ISpeechData,
	ISpeechProps,
	ISpeechValueData,
	IViewProps
} from './types'
import ModalReport from './view'
import {
	ArrayIsEmpty,
	handleInitializeArrayOptions,
	IMAGE_INITIAL_VALUE,
	INITAL_SPEECH_DATA,
	INITAL_SPEECH_VALUE,
	INITIAL_BODY_VALUE
} from 'shared/util/Consts'
import { IUser } from 'shared/interfaces/user'
import { IAttachment } from 'shared/interfaces/attachment'
import { IImageUpload } from 'shared/components/ImageUpload/types'
import { fetchAuthors, fullName } from 'shared/services/user.service'
import {
	createReport,
	deleteReport,
	getReportCategories,
	updateReport,
	getReportSubcategories,
	getReportSpeechCategories
} from 'shared/services/report.service'
import cogoToast from 'cogo-toast'
import { errorMessages, successMessages } from 'shared/util/Messages'
import cogoDefaultOptions from 'shared/util/toaster'
import {
	isYoutubeVideo,
	normalizeYoutubeLink,
	isPowerBIGraph
} from 'shared/util/youtubeValidator'
import { Node } from 'slate'
import {
	mapCategoryReport,
	mapCategorySpeechCategory,
	mapCategoryType,
	mapEquitiesSubcategory,
	mapSectorMonitorSubcategory
} from 'shared/util/translate'
import {
	ReportCategoryEnum,
	ReportCategoryValueEnum,
	ReportSubcategoryTypes,
	TypeSpeechEnum
} from 'shared/interfaces/report'
import { ISelectOption } from '@buildbox/components/lib/components/Select/types'
import { format, isFuture, isValid } from 'date-fns'
import { upload, remove } from 'shared/services/media.service'

function ModalReportContainer(props: IModalReportProps): JSX.Element {
	const {
		isActive,
		modalAction,
		currentReport,
		hideModal,
		reloadReportsPage,
		reloadDeleteReport
	} = props

	const { user } = useTypedSelector(['user'])
	const [title, setTitle] = useState<string>('')
	const [subtitle, setSubtitle] = useState<string>('')
	const [author, setAuthor] = useState<string>('')
	const [authorsOptions, setAuthorsOptions] = useState<SelectOption[]>([])
	const [selectedAuthor, setSelectedAuthor] = useState<SelectOption | null>(
		null
	)
	const [youtubeLink, setYoutubeLink] = useState<string>('')
	const [body, setBody] = useState<Node[]>(INITIAL_BODY_VALUE)
	const [emailNotificate, setEmailNotificate] = useState<boolean>(false)
	const [notificate, setNotificate] = useState<boolean>(true)
	const [authors, setAuthors] = useState<IUser[]>([])
	const [viewErrors, setViewErrors] = useState<string[]>([])
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [isFormValid, setFormValid] = useState<boolean | undefined>(false)
	const [pdfs, setPdfs] = useState<IAttachment[]>([])
	const [image, setImage] = useState<IImageUpload>(IMAGE_INITIAL_VALUE)
	const [isPublic, setIsPublic] = useState<boolean>(false)
	const [categoryOptions, setCategoryOptions] = useState<SelectOption[]>([])
	const [selectCategory, setSelectCategory] = useState<SelectOption>()
	const [category, setCategory] = useState<ReportCategoryEnum>(
		'POLITICS_MACRO_STRATEGY'
	)
	const [subcategoryOptions, setSubcategoryOptions] = useState<SelectOption[]>()
	const [selectSubcategory, setSelectSubcategory] = useState<SelectOption>()
	const [subcategory, setSubcategory] = useState<ReportSubcategoryTypes>()
	const [switchValue, setSwitchValue] = useState({
		portuguese: false,
		english: false
	})
	const [ArrayOptions, setArrayOptions] = useState<string[]>([])
	const [BZElectionAccess, setBZElectionAccess] = useState<boolean>()
	const [delayTimeInMacroPolitical, setDelayTimeInMacroPolitical] = useState(0)
	const [graphLink, setGraphLink] = useState('')
	const [isFixed, setIsFixed] = useState(false)

	const [speechDate, setSpeechData] = useState<ISpeechData>(INITAL_SPEECH_DATA)

	const [speechValue, setSpeechValue] = useState<ISpeechValueData>({
		candidate: {} as ISelectOption,
		city: '',
		date: '',
		theme: [],
		type: {} as ISelectOption
	})

	function handleGraphLink(event: React.ChangeEvent<HTMLInputElement>) {
		setGraphLink(event.target.value)
	}

	function handleSpeechChange(
		data: ISelectOption | ISelectOption[],
		id: string
	) {
		setSpeechValue((props) => ({
			...props,
			[id]: data
		}))
	}

	function handleSpeechTextInput(str: string, id: string) {
		setSpeechValue((props) => ({
			...props,
			[id]: str
		}))
	}

	function handleArrayOptions() {
		if (switchValue.english && switchValue.portuguese) {
			setArrayOptions(['PT', 'EN'])
		} else if (switchValue.english) {
			setArrayOptions(['EN'])
		} else if (switchValue.portuguese) {
			setArrayOptions(['PT'])
		} else {
			setArrayOptions([])
		}
	}

	function initializeReportFields() {
		setTitle(currentReport.title)
		setSubtitle(currentReport.subtitle)
		currentReport.author._id === ''
			? setAuthor(user._id)
			: setAuthor(currentReport.author._id)
		setBody(JSON.parse(currentReport.body))
		setImage({ file: null, url: currentReport.coverImage })
		setNotificate(currentReport.notificate)
		setYoutubeLink(currentReport.videoLink)
		setSwitchValue(handleInitializeArrayOptions(currentReport.languages))
		setEmailNotificate(currentReport.emailNotificate)
		setIsPublic(currentReport.isPublic)
		setCategory(currentReport.category)
		setSubcategory(currentReport.subcategory)
		setBZElectionAccess(currentReport.showInMacroPolitical)
		setDelayTimeInMacroPolitical(
			currentReport.delayMinutesForMacroPolitics || 0
		)
		setGraphLink(currentReport.webPageURL)
		setIsFixed(currentReport.isFixed || false)

		if (currentReport.category === 'SPEECH' && !!currentReport?.speech) {
			const typeMaped = {
				value: currentReport?.speech.type,
				label: mapCategoryType(currentReport?.speech.type)
			}

			const themeMaped = currentReport.speech?.theme.map((themeData) => ({
				value: themeData,
				label: mapCategorySpeechCategory(themeData)
			}))

			const dateFormated = currentReport.speech?.date
				? format(new Date(currentReport.speech?.date), 'dd/MM/yyyy')
				: ''

			// const themeMaped = currentReport.speech?..map((themeData) => ({

			setSpeechValue((props: any) => ({
				...props,
				candidate: {
					value: currentReport.speech?.candidate || '',
					label: currentReport.speech?.candidate || ''
				},
				theme: themeMaped,
				date: dateFormated,
				type: typeMaped,
				city: currentReport.speech?.city || ''
			}))
		}
	}

	const handleElectionAccess = useCallback(() => {
		setBZElectionAccess((state) => !state)
	}, [])

	const handleDelayMinuteChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			if (!!Number(event.target.value)) {
				const value = Number(event.target.value)
				const maxValue = 525600 // 525600 minutos = 1 ano
				value <= maxValue && setDelayTimeInMacroPolitical(value)
			} else {
				setDelayTimeInMacroPolitical(0)
			}
		},
		[]
	)

	async function getAllAuthors() {
		try {
			const authors = await fetchAuthors()
			setAuthors(authors)
		} catch (err) {}
	}

	function findAuthorSelect(): SelectOption | null {
		const authorTrendCreator = authors.find((x) => {
			return x._id === String(author)
		})

		if (authorTrendCreator === undefined) return null

		return {
			label: fullName(authorTrendCreator),
			value: authorTrendCreator._id
		}
	}

	async function handleDelete(reportId: string) {
		try {
			setIsLoading(!isLoading)
			await deleteReport(reportId)
			cogoToast.success(successMessages.liveDeleted, cogoDefaultOptions)
			hideModal()
			reloadDeleteReport()
		} catch (err) {
		} finally {
			setIsLoading(!isLoading)
		}
	}

	async function handleCoverImage(image: IImageUpload) {
		if (image.file) {
			return await upload(image.file)
		}

		return image.url || ''
	}

	async function handleUploadAttachment(pdf: IAttachment) {
		if (!pdf.file) return
		const url = await upload(pdf.file)
		return {
			url,
			name: pdf.name,
			size: pdf.size
		}
	}

	const changeDateFormat = (date: string) => {
		const dateArray = date.split('/')
		return `${dateArray[1]}/${dateArray[0]}/${dateArray[2]}`
	}

	async function handleSubmit(e: FormEvent<HTMLButtonElement>) {
		e.preventDefault()

		const errorsCount = handleViewError()

		if (!!!errorsCount) {
			setIsLoading(!isLoading)

			let coverImage = await handleCoverImage(image)

			const typeMaped = speechValue.type.value as TypeSpeechEnum
			const themeMaped = speechValue.theme.map((x: ISelectOption) => {
				return x.value as ReportCategoryValueEnum
			})

			const SpeechDataFormated: ISpeechProps = {
				candidate: speechValue.candidate?.value || '',
				type: typeMaped,
				theme: themeMaped,
				date: speechValue.date || '',
				city: speechValue.city || ''
			}

			let report: IModalReport = {
				title: title,
				subtitle: subtitle,
				author: author,
				createdBy: user._id,
				coverImage: coverImage,
				body: JSON.stringify(body),
				notificate: notificate,
				videoLink: normalizeYoutubeLink(youtubeLink),
				languages: ArrayOptions,
				attachments: [] as IAttachment[],
				emailNotificate: emailNotificate,
				isPublic: isPublic,
				category: category,
				subcategory: subcategory,
				showInMacroPolitical: BZElectionAccess ? BZElectionAccess : false,
				delayMinutesForMacroPolitics: Number(delayTimeInMacroPolitical),
				webPageURL: graphLink,
				isFixed: isFixed
			}

			if (selectCategory?.value === 'SPEECH') {
				report.speech = {
					...SpeechDataFormated,
					date: changeDateFormat(SpeechDataFormated.date as string)
				}
			}

			try {
				if (currentReport._id) {
					const toUpload = pdfs.filter((pdf) => !pdf._id && !pdf.url)
					const toDelete = currentReport.attachments.filter(
						(attachment) =>
							attachment._id && attachment.url && !pdfs.includes(attachment)
					)
					const toKeep = pdfs.filter(
						(pdf) => !toUpload.includes(pdf) && !toDelete.includes(pdf)
					)

					toDelete.map((pdf) => remove(String(pdf.url)))

					Promise.all(toUpload.map(handleUploadAttachment)).then(
						async (response) => {
							const attachments = [...response, ...toKeep]
							await updateReport(currentReport._id, {
								...report,
								attachments: attachments as IAttachment[]
							})
							cogoToast.success(successMessages.liveUpdated, cogoDefaultOptions)
							hideModal()
							reloadReportsPage()
						}
					)
				} else {
					Promise.all(pdfs.map(handleUploadAttachment)).then(
						async (response) => {
							await createReport({
								...report,
								attachments: response as IAttachment[]
							})
							cogoToast.success(successMessages.liveCreated, cogoDefaultOptions)
							hideModal()
							reloadReportsPage()
						}
					)
				}
			} catch (err) {
			} finally {
				setIsLoading((state) => !state)
			}
		}
	}

	function handleViewError() {
		const errors: string[] = []

		const verifyFiels = verifyFields()
		const verifyLink = youtubeLink !== '' ? isYoutubeVideo(youtubeLink) : true

		const graphLinkError = graphLink !== '' ? isPowerBIGraph(graphLink) : true

		if (!verifyLink) {
			errors.push(errorMessages.insertAValidLink)
		}

		if (!verifyFiels) {
			errors.push('Preencha todos os campos.')
		}

		if (!graphLinkError) {
			errors.push('O link do grafico precisa ser uma URL valida')
		}

		if (ArrayIsEmpty(ArrayOptions)) {
			errors.push('Preencha ao menos um idioma.')
		}

		if (selectCategory?.value === 'SPEECH') {
			const currentDate = speechValue.date || ''
			const formatedDate = changeDateFormat(currentDate as string)

			const selectedDate = new Date(formatedDate)

			const result = isFuture(selectedDate)

			if (!speechValue.candidate.value) {
				errors.push('Preencha um candidato.')
			}

			if (!speechValue.type) {
				errors.push('Preencha um tipo de discurso.')
			}

			if (!speechValue.theme) {
				errors.push('Preencha uma categoria de discurso.')
			}

			if (!speechValue.city) {
				errors.push('Preencha a cidade do discurso.')
			}

			if (!isValid(selectedDate)) {
				errors.push('Preencha uma data valida.')
			}

			if (!speechValue.date) {
				errors.push('Preencha a data do discurso.')
			}

			if (result) {
				errors.push('A data do discurso não pode ser pode ser futura.')
			}
		}

		setViewErrors(errors)
		return errors.length
	}

	function handleEffectCategories() {
		;(async () => {
			const reportCategories = await getReportCategories()
			const speechCategories = await getReportSpeechCategories()

			const categoryOptions = reportCategories.map((item: string) => ({
				value: item,
				label: mapCategoryReport(item)
			}))

			setCategoryOptions(categoryOptions)

			const candidateOptions = speechCategories.candidates.map((item) => ({
				value: item,
				label: item
			}))

			const categorySpeechOptions = speechCategories.categories.map((item) => ({
				value: item,
				label: mapCategorySpeechCategory(item)
			}))

			const categoryTypeOptions = speechCategories.types.map((item) => ({
				value: item,
				label: mapCategoryType(item)
			}))

			setSpeechData((props) => ({
				...props,
				candidates: candidateOptions,
				categories: categorySpeechOptions,
				types: categoryTypeOptions
			}))
		})()
	}

	function fetchSubcategories(category: ReportCategoryEnum) {
		;(async () => {
			if (category === 'EQUITIES' || category === 'SECTOR_MONITOR') {
				const subcategoriesResponse = await getReportSubcategories(category)

				const subcategoryOptions = subcategoriesResponse.map(
					(item: string) => ({
						value: item,
						label:
							category === 'EQUITIES'
								? mapEquitiesSubcategory(item)
								: mapSectorMonitorSubcategory(item)
					})
				)
				setSubcategoryOptions(subcategoryOptions)
				!subcategory &&
					subcategoryOptions[0] &&
					handleSelectSubcategory(subcategoryOptions[0])
			}
		})()
	}

	function verifyFields() {
		const allFieldsFilled = areAllFieldsFilled([title.trim()])

		const bodyString = JSON.stringify(body)

		const anyFieldChange = hasAnyFieldChange(
			title.trim(),
			subtitle.trim(),
			author,
			image.url,
			bodyString,
			notificate,
			pdfs,
			ArrayOptions,
			youtubeLink,
			emailNotificate,
			isPublic,
			category,
			subcategory,
			graphLink
		)

		const formValidate = allFieldsFilled && anyFieldChange

		setFormValid(formValidate)

		return formValidate
	}

	function areAllFieldsFilled(fields: string[]) {
		return fields.every((item) => {
			return item.length > 0
		})
	}

	function hasAnyFieldChange(
		pTitle: string,
		pSubtitle: string,
		pAuthor: string,
		pPreviewImage: string,
		pBodyString: string,
		pNotificate: boolean,
		pdfs: IAttachment[],
		language: string[],
		youtubeLink: string,
		pEmailNotificate: boolean,
		pIsPublic: boolean,
		pIsCategory: ReportCategoryEnum,
		pSubcategory: ReportSubcategoryTypes | undefined,
		webPageURL: string
	) {
		if (pTitle !== currentReport.title) return true
		if (pSubtitle !== currentReport.subtitle) return true
		if (pAuthor !== currentReport.author._id) return true
		if (pPreviewImage !== currentReport.coverImage) return true
		if (pBodyString !== currentReport.body) return true
		if (pNotificate !== currentReport.notificate) return true
		if (pEmailNotificate !== currentReport.emailNotificate) return true
		if (JSON.stringify(pdfs) !== JSON.stringify(currentReport.attachments))
			return true
		if (language !== currentReport.languages) return true
		if (youtubeLink !== currentReport.videoLink || youtubeLink === null)
			return true
		if (pIsPublic !== currentReport.isPublic) return true
		if (pIsCategory !== currentReport.category) return true
		if (pSubcategory !== currentReport.subcategory) return true
		if (webPageURL !== currentReport.subcategory) return true
	}

	function handleSelectChange(selectedOption: any) {
		if (!selectedOption) return

		setAuthor(selectedOption.value)
		setSelectedAuthor(selectedOption)
	}

	function handleEffectAuthors() {
		const authorsOptions = authors.map((item) => ({
			value: item._id,
			label: fullName(item)
		}))

		setAuthorsOptions(authorsOptions)
	}

	function handleEditor(value: Node[]) {
		setBody(value)
	}

	function handleImage(image: IImageUpload) {
		setImage(image)
	}

	function handleTitle(value: string) {
		setTitle(value)
	}

	function handleSubtitle(value: string) {
		setSubtitle(value)
	}

	function handleYoutubeLink(e: React.ChangeEvent<HTMLInputElement>) {
		setYoutubeLink(e.target.value)
	}

	function handlePdf(value: IAttachment[]) {
		setPdfs(value)
	}

	function handleSelectCategoryChange(selectedOption: SelectOption) {
		if (category !== selectedOption.value) {
			setSelectCategory(selectedOption)
			setCategory(selectedOption.value as ReportCategoryEnum)
			setSubcategoryOptions(undefined)
			setSelectSubcategory(undefined)
			setSubcategory(undefined)
		}
	}

	function handleSelectSubcategory(selectedOption: SelectOption) {
		setSelectSubcategory(selectedOption)
		setSubcategory(selectedOption.value as ReportSubcategoryTypes)
	}

	const handleIsFixed = useCallback(() => {
		setIsFixed((state) => !state)
	}, [])

	const modalValue: IModalValueProps = {
		title: title,
		subtitle: subtitle,
		image: image,
		youtubeLink: youtubeLink,
		selectedAuthor: selectedAuthor,
		body: body,
		notificate: notificate,
		emailNotificate: emailNotificate,
		isFormValid: isFormValid,
		language: ArrayOptions,
		isPublic: isPublic,
		category: selectCategory,
		subcategory: selectSubcategory
	}

	const modalOptions: IModalOptionsProps = {
		authorsOptions: authorsOptions,
		viewErrors: viewErrors,
		categoryOptions: categoryOptions,
		subcategoryOptions: subcategoryOptions
	}

	useEffect(() => {
		setSelectedAuthor(findAuthorSelect())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [author, authors])

	useEffect(handleEffectAuthors, [authors])

	useEffect(() => {
		initializeReportFields()
		getAllAuthors()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentReport])
	useEffect(() => {
		verifyFields()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		title,
		subtitle,
		author,
		body,
		image,
		notificate,
		youtubeLink,
		pdfs,
		ArrayOptions,
		emailNotificate,
		isPublic,
		category,
		subcategory
	])

	useEffect(() => {
		initializeReportFields()
		getAllAuthors()
		handleEffectCategories()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		setSelectCategory({
			label: mapCategoryReport(category),
			value: category
		})
		fetchSubcategories(category)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [category])

	useEffect(() => {
		if (subcategory) {
			setSelectSubcategory({
				value: subcategory,
				label:
					category === 'EQUITIES'
						? mapEquitiesSubcategory(subcategory)
						: mapSectorMonitorSubcategory(subcategory)
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subcategory])

	useEffect(handleArrayOptions, [switchValue])

	const viewProps: IViewProps = {
		isActive,
		modalAction,
		currentReport,
		hideModal,
		isLoading,
		initializeReportFields,
		handleImage,
		modalValue,
		modalOptions,
		handleEditor,
		handleSelectChange,
		handleTitle,
		handleSubtitle,
		handleYoutubeLink,
		handlePdf,
		handleSubmit,
		handleDelete,
		setNotificate,
		setEmailNotificate,
		setIsPublic,
		handleSelectCategoryChange,
		handleSelectSubcategory,
		setSwitchValue,
		switchValue,
		BZElectionAccess,
		delayTimeInMacroPolitical,
		handleElectionAccess,
		handleDelayMinuteChange,
		graphLink,
		handleGraphLink,
		isFixed,
		handleIsFixed,
		speechDate,
		speechValue,
		handleSpeechChange,
		handleSpeechTextInput
	}

	return createElement(ModalReport, viewProps)
}

export default ModalReportContainer
