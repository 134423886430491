import React, {
	ChangeEvent,
	PropsWithChildren,
	Ref,
	useEffect,
	useState
} from 'react'
import { Point } from 'slate'

import { useSlate } from 'slate-react'
import { Icon } from '../Icon'
import { OrNull, ButtonType } from '../types'
import {
	insertImage,
	isBlockActive,
	isMarkActive,
	toggleBlock,
	toggleMark
} from '../util'
import { ButtonWrapper } from './styles'
import { upload } from 'shared/services/media.service'

interface Range {
	anchor: Point
	focus: Point
	[key: string]: unknown
}

export const Button = React.forwardRef(function (
	{ className, type, format, icon, ...props }: PropsWithChildren<ButtonType>,
	ref: OrNull<Ref<HTMLSpanElement>>
) {
	const [lastSelection, setLastSelection] = useState<Range | null>(null)
	const editor = useSlate()
	const selection = editor.selection

	function handleMouseDown(event: any) {
		event.preventDefault()
		type === 'block' ? toggleBlock(editor, format) : toggleMark(editor, format)
	}
	const isActive =
		type === 'block'
			? isBlockActive(editor, format)
			: isMarkActive(editor, format)

	function onChangeInput(event: ChangeEvent<HTMLInputElement>) {
		;(async () => {
			// This piece of code is needed to return the cursor to last known location
			// and insert image in the right place
			if (lastSelection) {
				editor.selection = {
					anchor: lastSelection.anchor,
					focus: lastSelection.focus
				}
			}

			const selectedImage = event.target?.files?.item(0)
			event.target.value = ''
			const imageURL = await upload(selectedImage)

			insertImage(editor, imageURL)
		})()
		// event.preventDefault()
		// const url = window.prompt('Enter the URL of the image:')
		// if (!url) return
		// insertImage(editor, url)
	}

	useEffect(() => {
		if (!selection) return

		setLastSelection(selection)
	}, [selection])

	return (
		<ButtonWrapper
			{...props}
			onMouseDown={type === 'image' ? () => {} : handleMouseDown}
			htmlFor={type === 'image' ? 'button-hidden-input' : ''}
		>
			<Icon active={isActive} icon={icon} />

			{type === 'image' ? (
				<input
					className='button-hidden-input'
					id='button-hidden-input'
					type='file'
					accept='.png, .jpg, .jpeg'
					onChange={onChangeInput}
				/>
			) : null}
		</ButtonWrapper>
	)
})
