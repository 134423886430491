import axiosInstance from './axiosInstance'

export async function upload(fileParam: any): Promise<string> {
	const file: FormData = new FormData()
	file.append('file', fileParam)
	const response = await axiosInstance.post('/media/upload', file, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
	return response.data.url
}

export async function uploadBase64(fileBase64: string): Promise<string> {
	const response = await axiosInstance.post('/media/upload-base64', {
		fileBase64
	})
	return response.data.url
}

export async function remove(fileUrl: string): Promise<string> {
	const response = await axiosInstance.patch('/media/remove', { fileUrl })
	return response.data
}
